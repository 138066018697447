import '../css/app.css';
import '../sass/app.scss';
window.addEventListener("load", async function () {
  await import("./lang");
  const { paramsSerializer, createAuthInterceptor } = await import("./utils/common");

  import.meta.glob([
    '../img/**',
  ]);
  await import("./vendor/bootstrap");
  await import("./vendor/axios");
  await import("./pages/auth/index");

  const client = axios.create({
    baseURL: "/ajax/user",
    paramsSerializer,
  });

  createAuthInterceptor(client);
  const params = {};
  const { data } = await client.get("", { params });
  window.authUser = data.data

  await import("./utils/spinner");
  await import("./vendor/inputmask");
  await import("./vendor/flatpickr");
  await import("./vendor/dadata");
  await import("./vendor/select2");
  await import("./vendor/scroll-to-top");
  await import("./vendor/autoresize-textarea");
  await import("./vendor/nouislider");
  await import("./main");

  import("./components/inputs/address");
  import("./owners/add-owner")
  import("./owners/index");
  import("./components/header/header");
  import("./enterprises/index");
  import("./inventories/index");
  import("./disposal-list/index");
  import("./tasks/index");
  import("./users/index");
  import("./birk_storage/index");
  import("./registries/index");
  import("./pages/analytic/index");
  import("./diseases/index");
  import("./drugs/index");
  import("./drug-release-forms/index");
  import("./animals/index");
  import("./pages/tasks/index");
  import("./pages/home/index");
  import("./pages/auth/index");
  import("./pages/organizations/index");
  import("./pages/drugs");
  import("./batches/index");
  import("./messages/index");
  import("./enterprise-treatment/index");
  import("./treatment-products/index");
  import("./handbooks/index");
  import("./supervised-objects/index");
  import("./vetis/applications");


  // document.querySelectorAll("input").forEach((input) => {
  //   input.addEventListener("change", (e) => {
  //     e.target.value = e.target.value.trim();

  //     if (
  //       input.getAttribute("name") == "first_name" ||
  //       input.getAttribute("name") == "last_name" ||
  //       input.getAttribute("name") == "import_name" ||
  //       input.getAttribute("name") == "patronymic"
  //     ) {
  //       e.target.value = _.upperFirst(e.target.value);
  //     }

  //     if (input.getAttribute("name") == "email") {
  //       e.target.value = e.target.value.toLowerCase();
  //     }
  //   });
  // });
});
